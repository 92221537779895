import { useFilterHelperInterface } from '~/modules/catalog/category/composables/useFilterHelper/types';
import { SelectedFiltersInterface } from '~/modules/catalog/category/components/filters/useFilters';

export function useFilterHelper(context): useFilterHelperInterface {
  const getLabel = (label: string): string => {
    if (label) {
      return label.trim().toUpperCase();
    }
  };

  const resetCategory = (code: string, selectedFilters: SelectedFiltersInterface) => {
    context.emit('reset', { code: code, filter_arr: selectedFilters[code] });
  };

  const isResetButtonVisible = (code: string, selectedFilters: SelectedFiltersInterface): boolean => {
    return selectedFilters[code] && !!selectedFilters[code].length;
  };

  return {
    getLabel,
    isResetButtonVisible,
    resetCategory
  };
}

export default useFilterHelper;
