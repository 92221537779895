





































































































import LazyHydrate from 'vue-lazy-hydration';
import {
  SfButton,
  SfGallery,
  SfHeading,
  SfLoader,
  SfPrice,
  SfRating
} from '@storefront-ui/vue';
import {
  ref,
  computed,
  defineComponent,
  PropType,
  toRef
} from '@nuxtjs/composition-api';

import {
  getSwatchData as getProductSwatchData,
  getName as getProductName
} from '~/modules/catalog/product/getters/productGetters';

import {
  getTotalReviews,
  getAverageRating
} from '~/modules/review/getters/reviewGetters';

import { useCart } from '~/modules/core/composables/useCart';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import GroupedProductSelector from '~/modules/catalog/product/components/product-types/grouped/GroupedProductSelector.vue';
import SvgImage from '~/components/General/SvgImage.vue';
import HTMLContent from '~/components/HTMLContent.vue';
import AddToWishlist from '~/components/AddToWishlist.vue';
import { useUser } from '~/modules/customer/composables/useUser';
import { getGroupedProductPriceCommand } from '~/modules/catalog/pricing/getGroupedProductPriceCommand';
import { Product } from '~/modules/catalog/product/types';
import ProductTabs from '~/modules/catalog/product/components/tabs/ProductTabs.vue';
import { useProductGallery } from '~/modules/catalog/product/composables/useProductGallery';
import {
  TabsConfig,
  useProductTabs
} from '~/modules/catalog/product/composables/useProductTabs';

export default defineComponent({
  name: 'GroupedProduct',
  components: {
    GroupedProductSelector,
    HTMLContent,
    LazyHydrate,
    SfButton,
    SfGallery,
    SfHeading,
    SfLoader,
    SfPrice,
    SfRating,
    AddToWishlist,
    SvgImage,
    ProductTabs
  },
  transition: 'fade',
  props: {
    product: {
      type: [Object, null] as PropType<Product>,
      default: null
    },
    isFetching: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const qty = ref(1);
    const product = toRef(props, 'product');
    const { addItem, canAddToCart } = useCart();
    const { productGallery, imageSizes } = useProductGallery(product);
    const { activeTab, setActiveTab, openNewReviewTab } = useProductTabs();

    const { isAuthenticated } = useUser();
    const { addOrRemoveItem, isInWishlist } = useWishlist();
    const basePrice = ref(0);
    const openTab = ref(1);

    const productShortDescription = computed(
      () => props.product?.short_description?.html || ''
    );

    const productPrice = computed(() =>
      getGroupedProductPriceCommand(props.product)
    );
    const productSpecialPrice = 0;
    const totalReviews = computed(() => getTotalReviews(props.product));
    const averageRating = computed(() => getAverageRating(props.product));

    return {
      addItem,
      addItemToWishlist: addOrRemoveItem,
      averageRating,
      basePrice,
      canAddToCart,
      isAuthenticated,
      isInWishlist: computed(() => isInWishlist({ product: props.product })),
      openTab,
      productGallery,
      getProductName,
      getProductSwatchData,
      productPrice,
      productShortDescription,
      productSpecialPrice,
      qty,
      totalReviews,
      imageSizes,
      setActiveTab,
      openNewReviewTab,
      activeTab,
      TabsConfig
    };
  }
});
