import { render, staticRenderFns } from "./CategoryProductGrid.vue?vue&type=template&id=23181fb5&scoped=true&"
import script from "./CategoryProductGrid.vue?vue&type=script&lang=ts&"
export * from "./CategoryProductGrid.vue?vue&type=script&lang=ts&"
import style0 from "./CategoryProductGrid.vue?vue&type=style&index=0&id=23181fb5&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23181fb5",
  null
  
)

export default component.exports