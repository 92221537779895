import { defineStore } from 'pinia';
import { CategoryResult } from '~/composables';
import { CategoryTreeSearchParams } from '~/modules/catalog/category/types';
interface CategoryState {
  rawCategories: CategoryResult | null;
  categoryUrl: string | null;
  categoryType: string | null;
  categoryClearUrl: string | null;
  isCollectionSidebarOpen: boolean;
}

export const useCategoryStore = defineStore('category', {
  state: (): CategoryState => ({
    rawCategories: null,
    categoryUrl: null,
    categoryType: null,
    categoryClearUrl: null,
    isCollectionSidebarOpen: false
  }),
  actions: {
    async load(searchParams?: CategoryTreeSearchParams) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore "customCategoryList" dynamically added by custom-middleware
      const { data }: { data: { categories?: CategoryResult } } = await this.$nuxt.app.$vsf.$magento.api.customCategoryList(searchParams);
      this.rawCategories = data?.categories ?? null;
    },
    setClearUrl(newClearUrl: string) {
      this.categoryClearUrl = newClearUrl;
    }
  },
  getters: {
    categories(state) {
      if (state.rawCategories === null) {
        return null;
      }

      return state.rawCategories?.items[0];
    },
  },
});
