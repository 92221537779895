import { useContext } from '@nuxtjs/composition-api';
import { Aggregation, FilterEqualTypeInput, Products } from '~/modules/GraphQL/types';

export const getProductFilterByCategoryCommand = {
  execute: async (categoryIdFilter: FilterEqualTypeInput): Promise<Aggregation[]> => {
    const context = useContext();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore "getProductFilterByCategory" dynamically added by custom-middleware
    const { data } = await context.app.$vsf.$magento.api.getProductFilterByCategory<{ products: Products }>({ categoryIdFilter });

    if (data?.products?.aggregations) {
      return data.products.aggregations.sort(function (a, b) {
        return parseInt(a.position) - parseInt(b.position);
      });
    }

    return [];
  },
};

export default getProductFilterByCategoryCommand;
