import type { ComposableFunctionArgs } from '~/composables';
import type { FacetSearchParams } from '~/modules/catalog/category/composables/useFacet';
import { rangeFilters } from '~/modules/catalog/category/composables/useFacet/input/createProductAttributeFilterInput';

export function createCustomFiltersFilterInput(params: ComposableFunctionArgs<FacetSearchParams>, categoryID: number) {
  const attributeFilter: Record<string, { from: number; to: number } | { eq: unknown } | { in: unknown }> = {};
  const categoryFilter = {
    category_id: { eq: categoryID }
  };

  Object.keys(params).forEach((key: string) => {
    if (rangeFilters.includes(key)) {
      const range = { from: 0, to: 0 };
      const flatValue = params[key]
        .flatMap((inputFilter) => inputFilter.split('_'))
        .map((str: string) => Number.parseFloat(str))
        .sort((a, b) => a - b);

      [range.from] = flatValue;
      range.to = flatValue[flatValue.length - 1];

      attributeFilter[key] = range;
    } else if (typeof params[key] === 'string') {
      attributeFilter[key] = { eq: params[key] };
    } else if (params[key].length === 1) {
      attributeFilter[key] = { eq: params[key][0] };
    } else {
      attributeFilter[key] = { in: params[key] };
    }
  });

  return { ...attributeFilter, ...categoryFilter };
}
