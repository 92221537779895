import { computed } from '@nuxtjs/composition-api';
import { sharedRef } from '@vue-storefront/core';
import { defineStore } from 'pinia';

import { useContentfulPage } from '~/diptyqueTheme/composable/useContentfulPage';
import { getUniqueId } from '~/diptyqueTheme/helpers/getUniqueId';
import { useFacet } from '~/diptyqueTheme/modules/catalog/category/composables/useFacet';
import { Logger } from '~/helpers/logger';
import facetGetters from '~/modules/catalog/category/getters/facetGetters';

export const useCategoryDataStore = defineStore('categoryData', () => {
  const categoryProducts = sharedRef([], 'useCategoryDataStore-categoryProducts');
  const currentRouteData = sharedRef({}, 'useCategoryDataStore-currentRouteData');
  const { sectionList, getSectionList } = useContentfulPage('plp');
  const { result } = useFacet();

  const totalCategoryProductsNumber = computed(() => facetGetters.getPagination(result.value)?.totalItems);

  const loadBlocksList = async (id) => {
    try {
      Logger.debug('useCategoryDataStore/loadBlocksList');
      await getSectionList('plp', id);
    } catch (err) {
      Logger.debug('[ERROR] useCategoryDataStore/loadBlocksList', err);
    }
  };

  const contentfulCategoryBlocks = computed(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return sectionList.value?.listingBlocks || [];
  });

  const getBlocksBelow = computed(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return sectionList.value?.belowBlocks || [];
  });

  const getMixedProducts = computed(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (currentRouteData.value?.sort || (currentRouteData.value?.filters && Object.keys(currentRouteData.value.filters).length !== 0)) {
      return categoryProducts.value;
    }

    const mixedProducts = [...categoryProducts.value];

    if (contentfulCategoryBlocks.value?.length) {
      contentfulCategoryBlocks.value.forEach((blockWrapper) => {
        const block = { ...blockWrapper.block };
        block.uid = getUniqueId();

        if (blockWrapper?.position <= mixedProducts.length) {
          mixedProducts.splice(blockWrapper?.position - 1, 0, block);
        } else if (totalCategoryProductsNumber.value && blockWrapper?.position > totalCategoryProductsNumber.value) {
          mixedProducts.push(block);
        }
      });
    }

    return mixedProducts;
  });

  const pushIntoCategoryProducts = (product) => {
    categoryProducts.value.push(product);
  };

  return {
    loadBlocksList,
    currentRouteData,
    categoryProducts,
    contentfulCategoryBlocks,
    getMixedProducts,
    getBlocksBelow,
    pushIntoCategoryProducts
  };
});
